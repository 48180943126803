<template>
  <div
    class="check-signup_pay-type"
    @click.stop.prevent
  >
    <SRadio
      v-model="type"
      :label="checkedLabel"
      toggle
      theme="icon"
      gap="0"
      inline
      class="check-signup_pay-radio"
    >
      <span class="desc">{{ signUpDesc }}</span>
    </SRadio>
    <span 
      v-if="extraDesc"
      class="extra-desc"
    >{{ extraDesc }}</span>
  </div>
</template>

<script setup name="CheckSignupPayType">
import { SRadio } from '@shein-aidc/sui-radio'
import { computed } from 'vue'
const props = defineProps({
  paymentCode: {
    type: String,
    default: ''
  },
  vault: {
    type: Object,
    default: () => ({})
  },
  language: {
    type: Object,
    default: () => ({})
  },
  checkedLabel: {
    type: [String, Number, Boolean],
    default: ''
  }
})
const emits = defineEmits(['toggle'])

const signUpDesc = computed(() => {
  if (props.paymentCode === 'afterpay-card') {
    return props.language.SHEIN_KEY_PC_30645
  } else if (props.paymentCode === 'afterpay-cashapp') {
    return props.language.SHEIN_KEY_PC_32740
  } else if (props.paymentCode === 'dlocal-nupay') {
    return props.language.SHEIN_KEY_PC_32636
  } else if (props.paymentCode === 'klarna-klarna') {
    return props.language.SHEIN_KEY_PC_32639
  } else if (props.paymentCode === 'PayPal-GApaypal') {
    return props.language.SHEIN_KEY_PC_33678
  } else if (['alipay-kakaopay', 'alipay-naverpay', 'alipay-tosspay'].includes(props.paymentCode)) {
    return props.language.SHEIN_KEY_PC_34981
  } else {
    return ''
  }
})

const extraDesc = computed(() => {
  if (props.paymentCode === 'klarna-klarna') {
    return props.language.SHEIN_KEY_PC_32642
  } else if (props.paymentCode === 'dlocal-nupay') {
    return props.language.SHEIN_KEY_PC_32593
  } else {
    return ''
  }
})

const type = computed({
  get() {
    return props.vault.type
  },
  set() {
    emits('toggle', props.paymentCode)
  }
})
</script>

<style lang="less">
.check-signup_pay-type {
  padding: 4px 0;
  .flexbox();
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  .desc {
    line-height: 20px;
    font-size: 12px;
  }
  .extra-desc {
    display: block;
    width: 100%;
    flex-shrink: 0;
    margin-top: 7px;
    font-size: 12px;
    color: #666;
  }
  .check-signup_pay-radio {
    flex-shrink: 0;
    .sui-radio__check {
      .margin-r(4px);
    }
  }
}
</style>
